<template>
  <div>
    <table id="a4" class="a4 pg1" width="100%" v-align="top" border="0">
      <tbody>
        <tr>
          <td class="td">
            <table id="contract-details" width="100%" border="0">
              <tbody>
                <tr>
                  <td valign="top" id="pg-print" style="height: auto">
                    <table width="100%" border="0">
                      <tbody>
                        <tr>
                          <td valign="top">
                            <img
                              v-if="contrato.empresa == 'Viajar Resorts'"
                              src="@/assets/images/viajar-resorts.png"
                              alt="logo"
                              style="height: 70px"
                            />
                            <img
                              v-if="contrato.empresa == 'Voe Simples'"
                              src="@/assets/images/voe-simples.png"
                              alt="logo"
                            />
                          </td>
                        </tr>
                      </tbody>
                    </table>
                    <table width="100%" border="0">
                      <tbody>
                        <tr>
                          <td
                            align="center"
                            style="
                              font-size: 22px;
                              line-height: 1.2;
                              padding-top: 30px;
                              color: #000;
                            "
                          >
                            <strong style="font-weight: 600"
                              >CONTRATO DE PRESTAÇÃO DE SERVIÇOS DE
                              INTERMEDIAÇÃO DE PASSAGEM AÉREA</strong
                            >
                          </td>
                        </tr>
                        <tr>
                          <td
                            align="center"
                            style="
                              font-size: 18px;
                              padding-bottom: 15px;
                              color: #000;
                            "
                          >
                            <strong style="font-weight: 600"
                              >TERMO DO CONTRATANTE/PASSAGEIRO</strong
                            >
                          </td>
                        </tr>
                      </tbody>
                    </table>
                  </td>
                </tr>
              </tbody>
            </table>

            <table width="100%" border="0">
              <tbody>
                <tr>
                  <td
                    valign="top"
                    id="pg-print"
                    style="height: auto; padding-top: 10px"
                  >
                    <table
                      width="100%"
                      border="0"
                      cellpadding="0"
                      cellspacing="0"
                      style="font-size: 13px"
                    >
                      <tr>
                        <td
                          colspan="5"
                          style="font-size: 12px; color: #000"
                          v-html="oContrato('primeira')"
                        ></td>
                      </tr>
                    </table>
                  </td>
                </tr>
              </tbody>
            </table>
          </td>
        </tr>
      </tbody>
    </table>

    <div class="html2pdf__page-break avoidThisRow"></div>

    <table id="a4" class="a4 pg2" width="100%" border="0">
      <tbody>
        <tr>
          <td
            valign="top"
            class="td"
            style="font-size: 12px; color: #000"
            v-html="oContrato('segunda')"
          ></td>
        </tr>
      </tbody>
    </table>

    <div class="html2pdf__page-break avoidThisRow"></div>
  </div>
</template>

<script>
export default {
  name: "TravelContractVoeSimplesFederal",
  props: {
    id: Number,
    contract: Object,
    contrato: Object,
    contractDetailsSize: Number,
    temCartao: Boolean,
    temBoleto: Boolean,
    temTransferencia: Boolean,
    updateCardData: Boolean,
  },
  data() {
    return {
      textoContrato: [
        {
          id: 1,
          p: `<p>As partes signatárias do presente <strong>CONTRATO DE PRESTAÇÃO DE SERVIÇOS DE INTERMEDIAÇÃO DE SERVIÇOS DE TURISMO - TERMO DO CONTRATANTE/PASSAGEIRO</strong>, doravante simplesmente <strong>"Termo"</strong>, de um lado, denominada <strong>AGÊNCIA, FEDERAL OPERADORA DE VIAGENS E TURISMO LTDA</strong>, nome fantasia <strong>Voe Simples</strong>, inscrita no CNPJ sob o nº 09.017.003/0001-02, com endereço na rua Rio Grande do Norte nº1436, 9º andar, Belo Horizonte – MG, por seu representante legal abaixo-assinado; e, de outro lado, denominado <strong>CONTRATANTE</strong>:</p>`,
        },
        {
          id: 2,
          p: `<p><strong>${this.contrato.cliente.nome}</strong>, inscrito no CPF número <strong>${this.contrato.cliente.cpf}</strong>, portador do Documento de Identidade número <strong>${this.contrato.cliente.rg}</strong>, residente e domiciliado em <strong>${this.contrato.cliente.endereco.rua}, ${this.contrato.cliente.endereco.numero}, ${this.contrato.cliente.endereco.cep}, ${this.contrato.cliente.endereco.cidade}, ${this.contrato.cliente.endereco.estado}, BRASIL</strong>.</p>`,
        },
        {
          id: 3,
          p: `<p>Considerando que:</p>`,
        },
        {
          id: 4,
          p: `<p>- O <strong>CONTRATANTE</strong> adquiriu, junto à <strong>AGÊNCIA</strong>, o(s) seguinte(s) bilhete(s) aéreo(s) que foi(foram) emitido(s) pela <strong>AGÊNCIA</strong>, por meio da utilização de milhas/pontos de programas de fidelidade de companhias aéreas conforme <strong>Anexo I</strong>.</p>`,
        },
        {
          id: 5,
          p: `<p>- Em favor dos passageiros conforme descriminados no <strong>Anexo I</strong>.</p>`,
        },
        {
          id: 6,
          p: `<p>- O montante total do(s) referido(s) bilhete(s), neste contrato será de <strong>R$ ${this.contrato.aereo.valor}, ${this.contrato.aereo.valor_extenso}</strong>, a ser quitado <strong>CARTÃO</strong>, em <strong>${this.contrato.pagamento.credito.cartao[0].parcelas}x</strong> a ser pago no cartão de crédito final <strong id="digitos">xxxx</strong>.</p>`,
        },
        {
          id: 7,
          p: `<p>Tem entre si ajustadas as cláusulas que seguem:</p>`,
        },
        {
          id: 8,
          p: `<p><strong>Cláusula 1ª.</strong> Por meio do presente instrumento, o <strong>CONTRATANTE</strong> declara ter ciência de adquiriu o(s) bilhete(s) aéreo(s) emitido(s) com a utilização de milhas aéreas/pontos de programas de fidelidade, e que em decorrência desta forma de emissão, obteve evidente vantagem financeira na compra de um produto promocional. Entende que ao optar por esta modalidade, a fruição do bilhete não terá o condão de acumular pontuação nos programas de fidelidade de titularidade do(s) PASSAGEIRO(s).</p>`,
        },
        {
          id: 9,
          p: `<p><strong>Cláusula 2ª.</strong> O <strong>CONTRATANTE</strong> declara saber e concordar que a transportadora contratada, doravante simplesmente, <strong>COMPANHIA AÉREA</strong>, registrada como  ${this.ciasName(
            this.contrato.aereo.cias,
            "fantasia"
          )} inscrita no CNPJ sob o nº ${this.ciasName(
            this.contrato.aereo.cias,
            "cnpj"
          )} nome fantasia <strong>${this.ciasName(
            this.contrato.aereo.cias,
            "fantasia"
          )}</strong> autorizada a funcionar no Brasil através do Decreto nº 38.817, de 05 de março de 1956, é a única responsável, uma vez gerado código de reserva, seguido de número de bilhete identificador, pelas regras de validade, alterações e cancelamento do(s) bilhete(s). Possíveis alterações em horários de pouso e decolagem, assim como reacomodações devido a voos cancelados e respectiva disponibilidade de lugares, são de exclusiva responsabilidade da, <strong>COMPANHIA AÉREA</strong>.</p>`,
        },
        {
          id: 10,
          p: `<p><strong>Cláusula 3ª.</strong> O <strong>CONTRATANTE</strong> declara ter ciência da impossibilidade de remarcação do(s) bilhete(s) adquirido(s) sem a interveniência da <strong>AGÊNCIA</strong>, que desde já disponibiliza seu departamento de atendimento ao cliente durante o horário comercial, informando que eventuais ocorrências de “no show”, ou seja, o não comparecimento para o embarque no horário contratado, não será de responsabilidade da <strong>AGÊNCIA</strong>, podendo gerar um prazo de até vinte e quatro horas úteis para remarcações.</p>`,
        },
        {
          id: 11,
          p: `<p><strong>Cláusula 4ª.</strong> O <strong>CONTRATANTE</strong> declara ter ciência que qualquer solicitação de remarcação de data, origem, destino ou cancelamento deverá ser com o mínimo de 24 horas úteis antes do voo.</p>`,
        },
        {
          id: 12,
          p: `<p><strong>Cláusula 5ª.</strong> O <strong>CONTRATANTE</strong> declara ser o único responsável pelos dados pessoais do(s) passageiro(s), afirmando ter fornecido e conferido o(s) nome(s) registrado(s) acima após a emissão do(s) bilhetes(s). Declara estar ciente de que qualquer irregularidade em relação ao documento oficial válido para embarque poderá ocasionar na recusa do(s) passageiro(s) para embarque por parte da(s) companhia(s) aérea(s) contratadas e inevitáveis multas e taxas previstas neste termo.</p>`,
        },
        {
          id: 13,
          p: `<p><strong>Cláusula 6ª.</strong> O <strong>CONTRATANTE</strong> declara conhecer e concordar com o funcionamento das regras estipuladas a cerca de alterações, cancelamento e reembolso dos bilhetes emitidos, a saber:</p>`,
        },
        {
          id: 14,
          p: `<p><strong>Cláusula 6.1ª.</strong> Validade do(s) bilhete(s): o(s) bilhetes aéreos tem validade de 02 (dois) ano(s) da data original de emissão. Após este prazo será expirado e não terá validade para remarcação e/ou reembolso.</p>`,
        },
        {
          id: 15,
          p: `<p><strong>Cláusula 6.2ª.</strong> <strong>Primeira alteração: Será permitido, dentro da validade do bilhete, mediante disponibilidade de lugares da companhia aérea e respeitando as condições originais da tarifa adquirida, 01 (uma) alteração sem custo, desde que o(s) passageiro(s) não tenha(m) ocorrido em no show, não haja diferença de pontuação da emissão original e taxas de embarque. Deve ser solicitado com o mínimo de 24 horas úteis antes do primeiro voo e obedecer ao itinerário original e companhia aérea.</strong></p>`,
        },
        {
          id: 16,
          p: `<p><strong>Cláusula 6.3ª.</strong> <strong>Taxa de alteração: Será devido o valor de EUR 200,00 por passageiro, para toda e qualquer alteração, com exceção prevista no item 6.2.</strong></p>`,
        },
        {
          id: 17,
          p: `<p><strong>Cláusula 6.4ª.</strong> <strong>Diferença de tarifa: Será devido sempre que houver diferença de pontuação em relação a emissão original. Deverá ser calculada e paga no momento do requerimento da remarcação do bilhete, sem prejuízo das taxas previstas no item 6.3.</strong></p>`,
        },
        {
          id: 18,
          p: `<p><strong>Cláusula 6.5ª.</strong> <strong>Cancelamento/Reembolso: (solicitado antes do embarque): Cancelamento seguido do pedido de reembolso poderá ocorrer com o mínimo de 24 horas úteis antes do embarque, mediante a cobrança no montante de EUR 350,00 por passageiro;</strong></p>`,
        },
        {
          id: 19,
          p: `<p><strong>Cláusula 6.6ª.</strong> <strong>Taxa de cancelamento da Voe Simples: informamos que a título de honorários de consultoria e despesas operacionais, sempre que houver um cancelamento/reembolso será devido uma taxa de 15% do valor total deste contrato, sem prejuízo, se cabível, das taxas cobradas no(s) itens 6.5, 6.7.</strong></p>`,
        },
        {
          id: 20,
          p: `<p><strong>Cláusula 6.7ª.</strong> <strong>Não embarque (no show): em caso de atraso ou não comparecimento ao procedimento de embarque (check-in), a companhia aérea cancelará automaticamente a reserva e será devido o valor pago do valor de EUR 100,00, além do previsto nos itens 6.3, 6.4, 6.5, 6.6.</strong></p>`,
        },
        {
          id: 21,
          p: `<p><strong>Cláusula 6.8ª.</strong> <strong>Não é aceito reembolso parcial de viagens já iniciadas em qualquer etapa.</strong></p>`,
        },
        {
          id: 22,
          p: `<p><strong>Cláusula 6.9ª.</strong> <strong>O CONTRATANTE declara ter ciência da impossibilidade de endosso deste bilhete(s), ou seja, transferência entre empresas aéreas.</strong></p>`,
        },
        {
          id: 23,
          p: `<p><strong>Cláusula 7ª.</strong> Parágrafo único: O  <strong>CONTRATANTE</strong> declara ter ciência de que o pedido de cancelamento do(s) bilhete(s) deverá ser formalizado por escrito na forma legal. O CONTRATANTE receberá em dinheiro o valor líquido do reembolso em até 30 (trinta) dias após a solicitação, na hipótese de pagamento do(s) bilhete(s) em dinheiro. Caso tenha efetuado a compra via cartão de crédito, o valor líquido de reembolso será estornado para a operadora do cartão pela qual foi realizada a operação, única responsável pelo prazo de reembolso. Prazo médio de 90 (noventa) dias.</p>`,
        },
        {
          id: 24,
          p: `<p style="margin: 30px 0">Belo Horizonte, ${this.contrato.cliente.data}.</p>`,
        },
      ],
    };
  },
  methods: {
    calcFirstPage(index) {
      return index <= 10;
    },
    calcSecondPage(index) {
      return index >= 11 && index <= 26;
    },
    calcThirdPage(index) {
      return index >= 19;
    },
    reRenderCard(pagamento) {
      let text = "xxxx";

      setTimeout(() => {
        text = pagamento.credito.cartao[0].numeros.quarto;
      });

      return text;
    },
    ciasName(cias, data) {
      let name = "";

      cias.forEach((cia) => {
        if (data == "fantasia") {
          name += `${cia.nome_fantasia}, `;
        }

        if (data == "cnpj") {
          name += `${cia.cnpj}, `;
        }

        if (data == "razao") {
          name += `${cia.razao_social}, `;
        }
      });
      return name.toUpperCase();
    },
    oContrato(page) {
      let todosParagrafos = "";
      this.textoContrato.forEach((paragrafo, index) => {
        paragrafo;
        index;
        if (this.calcFirstPage(index) && page == "primeira") {
          todosParagrafos += paragrafo.p;
        }

        if (this.calcSecondPage(index) && page == "segunda") {
          todosParagrafos += paragrafo.p;
        }

        if (this.calcThirdPage(index) && page == "terceira") {
          todosParagrafos += paragrafo.p;
        }
      });

      if (this.updateCardData) {
        setTimeout(() => {
          document.getElementById("digitos").innerText =
            "•••" + this.contrato.pagamento.credito.cartao[0].numeros.quarto;
        }, 50);
      }

      return todosParagrafos;
    },
  },
};
</script>
